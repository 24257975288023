import React from 'react'
import styled from 'styled-components'
import FooteLinkIcon from '../../assets/images/footerLink_icon.png'
import BLogo from '../../assets/images/zth.png'
import Z1LogoText from '../../assets/images/z1text.png'
import './Footer.css'


const Link01: React.FC = () => {
    return (
      <FLink01B>
        <StyledAbsoluteLinkA href="https://help.z1swap.com/addtoken" target="_blank">LIST NEW TOKEN</StyledAbsoluteLinkA> |
        <StyledAbsoluteLinkA href="https://z1swap.com/tokens/" target="_blank">DEFAULT TOKEN LIST</StyledAbsoluteLinkA>
      </FLink01B>
    )
}

const StyledIcon01 = styled.a`
    cursor:pointer; 
    text-decoration: none;
    width:25px;
    height:25px;
    margin:0 16px;
    display:inline-block;
    background:url(${FooteLinkIcon}) 50% 50% no-repeat;
`
const StyledAbsoluteLinkA = styled.a`
    cursor:pointer; 
    text-decoration: none;
    color:#fff;
    font-family: 'Exo 2', sans-serif;
	text-transform: uppercase;
    margin:0 16px;
    &:hover {
        color: cyan;
    }
`
const StyledAbsoluteLinkB = styled.a`
    cursor:pointer; 
    text-decoration: none;
    color:#fff;
    font:normal 12px/16px 'Exo 2', sans-serif;
	text-transform: uppercase;
    margin:0 6px;
    &:hover {
        color: cyan;
    }
`

const FooterFrame = styled.footer`

    background-color:#070707;
    width:100%;
    height:100px; 
    margin-top:40px;
    display:flex;
    align-items:center;
    padding:28px 20px;
    flex-wrap:wrap;
    position:relative;
    @media (min-width: 320px) and (max-width: 500px){
        flex-wrap: wrap;
        flex-direction: row;
    }
    @media (min-width: 501px) and (max-width: 767px){
        height:auto;
        padding:10px 10px 30px 10px;
    }
    @media (min-width: 768px) and (max-width: 991px){
        height:auto;
        padding:10px 10px 30px 10px;
    } 
`

const JRLogo = styled.div`
    margin-right:auto;
    min-width:110px;

    @media (min-width: 320px) and (max-width: 500px){
        margin:0 auto 20px auto;
    }
`

const FooterCbox = styled.div`
    text-align:center;
    margin:0 auto;
`

const FLink01B = styled.div`
    font:normal 15px/16px arial;color:#424242;
    padding-bottom:8px;
    display:flex;
    align-items:center;
    justify-content:center;
`

const FLink02B = styled.div`
    font:normal 15px/16px arial;color:#2a2a2a;
    padding-bottom:8px;
`

const ZTHLogo = styled.div`
    margin-left:auto;
    @media (min-width: 320px) and (max-width: 500px){
        margin:0 auto 20px auto;
    }
`

const ZTHLogoLink = styled.a`
    &:hover {
        opacity:0.7;
    }
`

const FooterBody = styled.div`
    display:block;
    width:100%;
    position: fixed;
    left: 0;
    bottom: 0;
    

`
const Footer: React.FC = () => {
    
    return (
        <FooterBody>
            <FooterFrame>
				<ZTHLogo>
                    <ZTHLogoLink href="#"><img src={Z1LogoText} height="36px;" /> </ZTHLogoLink>
                </ZTHLogo>
                <FooterCbox>
                    <Link01 />
                    
                </FooterCbox>
            </FooterFrame>
        </FooterBody>
    )}

export default Footer