import React from 'react'
import styled from 'styled-components'

export const BodyWrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  max-width: 500px;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 17px 1px #3b82f7;
  border-radius: 13px;
  padding: 30px 17px;
  pointer-events: ${({ disabled }) => disabled && 'none'};
`

/**
 * The styled container element that wraps the content of most pages and the tabs.
 */
export default function AppBody({ children, disabled }: { children: React.ReactNode; disabled?: boolean }) {
  return <BodyWrapper disabled={disabled}>{children}</BodyWrapper>
}
